/* Customize your main colors in :root variables */
:root {
  --main-background-color: #333c54;
  --card-background-color: #656d8b;
  --countdown-background-color: #547254;
  --main-text-color: #ffffff;
  --title-text-color: #52b104;
}

body{
  background-color:var(--main-background-color);
  /*background: url("img/background.png");*/
  background-size: cover;
  font-family: 'Lato', sans-serif;
  font-size: 1.1em;
}

h2{
  #text-align: center;
  font-family: "Metal Mania",cursive;
  font-size: 2.5em;
  color: #52b104;
}

h3{
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 1.5em;
}

p{
  font-family: 'Lato', sans-serif;
  font-size: 1.1em;
}
